import SlugifyUtils from './SlugifyUtils'

export function getEndpointFromMenu(processedDataMenu, pathSlug) {
  // console.log('    -> pathSlug: ', pathSlug)
  // console.log('    -> processedDataMenu: ', processedDataMenu)
  const generateSlug = SlugifyUtils()

  for (const menuItem of processedDataMenu.menu_items) {
    for (const submenuItem of menuItem.submenu) {
      if (pathSlug === generateSlug(submenuItem.label)) {
        return submenuItem.endpoint
      }
    }
  }

  return null
}

// ex. sectors with variable slug in translations
export function getEndpointFromMenuWithSlug(processedDataMenu, urlPath) {
  for (const menuItem of processedDataMenu.menu_items) {
    for (const submenuItem of menuItem.submenu) {
      if (urlPath === submenuItem.path) {
        return submenuItem.endpoint
      }
    }
  }

  return null
}

// get internal navigation menu  'about', 'sectors', 'services', 'approach', 'contacts'
export function getInternalNavMenu(processedDataMenu, area) {
  let areaObject = null

  for (const item of processedDataMenu.menu_items) {
    if (item.id === area) {
      areaObject = item
      break
    }
  }

  if (areaObject) {
    const result = {
      id: areaObject.id,
      label: areaObject.label,
      menu_items: areaObject.submenu.map((subItem) => ({
        label: subItem.label,
        path: subItem.path,
        slug: subItem.endpoint.replace(/\.json$/, ''),
      })),
    }

    return result
  } else {
    return null
  }
}
